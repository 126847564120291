// set basepath of project for webpack
import { basePath } from './core/config'
__webpack_public_path__ = `${basePath}/assets/frontend/js/`

import 'console-polyfill'
import elementClosestPolyfill from 'element-closest'
elementClosestPolyfill(window)
import 'custom-event-polyfill'

import './core/Sentry'

import $ from 'jquery'

// jquery utils
import 'jquery.easing'

import './plugins/nette.ajax'

// nette
import './nette/NetteAutosubmit'
import './nette/NetteValidationMessages'
import './nette/NetteSnippets'
import './nette/NetteEvents'
import './nette/NetteLoading'

import Nette from './nette/NetteForms'
window.Nette = Nette
Nette.initOnLoad()

if ($.nette) {
    $.nette.init()
}

import TouchMouseInputResolver from './meta/TouchMouseInputResolver'
new TouchMouseInputResolver()

import { android, iOS, IE, CMS } from './core/config'
import ScrollService from './services/Scroll'

if ( IE || android || iOS || CMS || (typeof DISABLE_SMOOTH_SCROLL !== 'undefined' && DISABLE_SMOOTH_SCROLL) || document.body.classList.contains('u-nativeScroll')) {
    ScrollService.useNative()
    setScrollPosition()
} else {
    (async () => {
        await ScrollService.useVirtual()
        setScrollPosition()
    })()
}


import { loadComponents, removeComponents } from './core/componentUtils'
loadComponents()

/**
 * lazy loading
 */
import lazySizes from 'lazysizes'
lazySizes.cfg.lazyClass = 'is-lazy'
lazySizes.cfg.loadingClass = 'is-lazy-loading'
lazySizes.cfg.loadedClass = 'is-lazy-loaded'


import Swup from 'swup'
// import SwupGtmPlugin from '@swup/gtm-plugin'
import SwupBodyClassPlugin from '@swup/body-class-plugin'
import { queryAll } from './utils/dom'
import EventBus from './core/EventBus'
import { EVENTS } from './core/Events'
import Component from './core/Component'

function setScrollPosition() {
    const id = window.location.hash.replace('#', '')
    const anchor = document.getElementById(id)

    if (id && anchor) {
        const animate = ScrollService.isVirtual ? false : true
        ScrollService.scrollToElement(anchor, { animate: animate, mode: 'auto' })
    } else {
        ScrollService.setPosition(0, 0)
    }
}

let isTabSwup = false
let nextPageName = null
const lastScrollPosition = {
    x: 0,
    y: 0
}

if (!CMS) {
    const swup = new Swup({
        linkSelector: [
            'a[href^="' + window.location.origin + '"]:not([data-no-swup]):not([target="_blank"]):not(.ajax)',
            'a[href^="/"]:not([data-no-swup]):not([target="_blank"]):not(.ajax)',
            // 'a[href^="#"]:not([data-no-swup]):not(.ajax)'
        ].join(', '),
        // animationSelector: '.Glare',
        plugins: [
            new SwupBodyClassPlugin(),
            // new SwupGtmPlugin()
        ],
        containers: ['#swup',  '#modals',  '#blob-page-name', '#illu-footer', '#nav-mobile-wrap', '#nav-wrap'],
        skipPopStateHandling: event => {
            if (event?.state?.source === 'swup') {
                return false
            }
            return true
        }
    })

    swup.on('samePage', event => {
        if (event.delegateTarget.dataset.forceSamePage !== undefined) {
            ScrollService.scrollToElement(document.querySelector('.Header'))
        }
    })

    swup.on('clickLink', event => {
        //pokud swupuje mezi taby, tak drzet pozici scrollu
        isTabSwup = event.delegateTarget.dataset.swupTransition === 'swup-tab' ? true : false
        nextPageName = event.delegateTarget.dataset.page || null

        EventBus.emit(EVENTS.SWUP_CLICK_LINK, { event })
    })

    swup.on('transitionStart', event => {
        const blob = Component.getFromElement('blob')

        if (nextPageName) {
            //z linku se vi, na kterou stranku se prechazi a muze se blobnout uz driv
            blob.run(blob.currentPageName, nextPageName)
        } else {
            //nevi se, kam se jde, takze muze maximalne odjet
            blob.run(blob.currentPageName, null)
        }
    })

    swup.on('willReplaceContent', event => {
        if (isTabSwup) {
            lastScrollPosition.x = ScrollService.getScroll().x
            lastScrollPosition.y = ScrollService.getScroll().y
        } else {
            lastScrollPosition.x = 0
            lastScrollPosition.y = 0
        }

        EventBus.emit(EVENTS.SWUP_WILL_REPLACE_CONTENT)

        queryAll('[data-swup]').forEach(element => {
            removeComponents(element);
        })
    })

    swup.on('contentReplaced', event => {
        Nette.reinit()

        if (android || IE || iOS || CMS || (typeof DISABLE_SMOOTH_SCROLL !== 'undefined' && DISABLE_SMOOTH_SCROLL) || document.body.classList.contains('u-nativeScroll')) {
            ScrollService.useNative()
        } else {
            // ScrollService.useVirtual()
        }

        queryAll('[data-swup]').forEach(element => {
            loadComponents(element);
        })

        if ($.nette) {
            $.nette.load()
        }

        setTimeout(() =>{
            ScrollService.resize()
            setScrollPosition(lastScrollPosition.x, lastScrollPosition.y)
        }, 0)

        setTimeout(() => {
            if (nextPageName === null) {
                //pri kliknuti na link se nevedelo, kam to povede, ted uz se vi...
                const blob = Component.getFromElement('blob')
                const pageName = blob.getCurrentPageNameFromDOM()
                blob.run(null, pageName)
            } else {
                nextPageName = null
            }
        }, 300)

    })
}
