export const EVENTS = {
    DOM_CHANGE: 'dom:change',

    SWUP_CLICK_LINK: 'swup:clicklink',
    SWUP_WILL_REPLACE_CONTENT: 'swup:willreplacecontent',
    SWUP_CONTENT_REPLACED: 'swup:contentreplaced',

    MODAL_OPEN: 'modal:open', //natvrdo v Modal.js
    MODAL_CLOSE: 'modal:close', //natvrdo v Modal.js
    MODALOPENER_CLICK: 'modalopener:click', //natvrdo v Modal.js

    FORMSIGNAL_DO: 'formsignal:do'
}